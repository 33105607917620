import { AxiosRequestConfig } from 'axios'
import { DELETE, GET, POST, PUT } from 'src/api/utilities/methods.api'
import IScanImage from 'src/structures/Interfaces/IScanImage'
import {
    IScansessionByIdResponse,
    IScansessionCreateResponse,
    IScansessionsResponse,
    ISetScanSessionLocation,
    IUploadImageRequest,
    IUploadImageRequestResponse,
    IUploadScanSessionRequest,
} from 'src/structures/Interfaces/IScansessionsResponse'
import {
    SCAN_SESSION_BY_ID_ENDPOINT,
    SCAN_SESSION_DELETE_IMAGE,
    SCAN_SESSION_SET_LOCATION,
    SCAN_SESSION_UPLOAD_IMAGE,
    SCAN_SESSION_UPLOAD_IMAGE_REQUEST,
    SCAN_SESSIONS_ENDPOINT,
} from './ScansessionsEndpoints'

export const getScanSessions = async () => {
    return GET<IScansessionsResponse[]>(SCAN_SESSIONS_ENDPOINT)
}

export const getScanSessionById = async (sessionId: string) => {
    return GET<IScansessionByIdResponse>(SCAN_SESSION_BY_ID_ENDPOINT(sessionId))
}

export const createScanSession = async () => {
    return POST<IScansessionCreateResponse, null>(SCAN_SESSIONS_ENDPOINT, null)
}

export const SetScanSessionLocation = async (sessionId: string, patchData: ISetScanSessionLocation) => {
    return POST<void, ISetScanSessionLocation>(SCAN_SESSION_SET_LOCATION(sessionId), patchData)
}

export const uploadScanSessionImage = async (sessionId: string, postData: { image_url: string; format: string }) => {
    return POST<void, IUploadScanSessionRequest>(SCAN_SESSION_UPLOAD_IMAGE(sessionId), postData)
}

export const uploadImageRequest = async (postData: IUploadImageRequest) => {
    return POST<IUploadImageRequestResponse, IUploadImageRequest>(SCAN_SESSION_UPLOAD_IMAGE_REQUEST, postData)
}

export const uploadImageToUrl = async (url: string, blob: Blob, config?: AxiosRequestConfig) => {
    return PUT<void, Blob>(url, blob, config)
}

export const deleteScanSessionImage = async (sessionId: string, deleteData: IScanImage) => {
    return DELETE<void>(SCAN_SESSION_DELETE_IMAGE(sessionId, deleteData.id))
}
