import IUserResponse from '../Interfaces/IUserResponse'

class User {
    id: string

    firstname: string

    name: string

    email: string

    supplier: {
        id: number
        name: string
    }

    scanSessions: {
        id: string
        created_at: string
        processed_at: string
    }[]

    constructor(userData: IUserResponse) {
        this.id = userData.id
        this.email = userData.email
        this.firstname = userData.firstname
        this.name = userData.name
        this.scanSessions = userData.scan_sessions
        this.supplier = userData.supplier
    }
}

export default User
