import IMenuScannerFile from 'src/structures/Interfaces/IMenuScannerFile'

export const filesToBase64 = async (files: File[]) => {
    const imageUrls: IMenuScannerFile<string>[] = await Promise.all(
        files.map((file) => {
            return new Promise<IMenuScannerFile<string>>((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    if (reader.result && typeof reader.result === 'string') {
                        resolve({
                            image: reader.result,
                            format: file.type,
                            name: file.name,
                            size: Math.ceil(file.size / (1024 * 1024)).toString(),
                        })
                    } else {
                        reject(new Error('FileReader failed to read the file.'))
                    }
                })
                reader.addEventListener('error', () => {
                    reject(new Error('FileReader encountered an error.'))
                })
                reader.readAsDataURL(file)
            })
        })
    )
    return imageUrls
}

export const filesToBlob = async (files: File[]) => {
    const imageBlobs: IMenuScannerFile<Blob>[] = await Promise.all(
        files.map((file) => {
            return new Promise<IMenuScannerFile<Blob>>((resolve, reject) => {
                const reader = new FileReader()

                reader.addEventListener('load', () => {
                    if (reader.result instanceof ArrayBuffer) {
                        const blob = new Blob([reader.result], { type: file.type })

                        resolve({
                            image: blob,
                            format: file.type,
                            name: file.name,
                            size: Math.ceil(file.size / (1024 * 1024)).toString(),
                        })
                    } else {
                        reject(new Error('FileReader failed to read the file as an ArrayBuffer.'))
                    }
                })

                reader.addEventListener('error', () => {
                    reject(new Error('FileReader encountered an error.'))
                })

                reader.readAsArrayBuffer(file)
            })
        })
    )

    return imageBlobs
}

export const urlToBase64 = async (url: string) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            const base64data = reader.result
            resolve(base64data)
        }
    })
}

type IExtensions = Record<string, string[]>
export const getExtensionsForMimetypes = (mimetypes: string[]) => {
    const extensions: IExtensions = {}

    for (const mimeType of mimetypes) {
        switch (mimeType) {
            case 'image/png': {
                extensions[mimeType] = ['.png']
                break
            }
            case 'image/gif': {
                extensions[mimeType] = ['.gif']
                break
            }
            case 'image/jpeg':
            case 'image/jpg': {
                extensions[mimeType] = ['.jpeg', '.jpg']
                break
            }
            case 'image/webp': {
                extensions[mimeType] = ['.webp']
                break
            }
            case 'image/svg+xml': {
                extensions[mimeType] = ['.svg']
                break
            }
            // Add more mimetypes and their corresponding extensions as needed
            default: {
                extensions[mimeType] = []
            }
        }
    }

    return extensions
}
