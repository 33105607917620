import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { SetScanSessionLocation } from 'src/api/client/scansessions/ScansessionsActions'
import Button, { ButtonStyle } from 'src/components/01_atoms/Button/Button'
import GooglePlaceAutoCompleteInput from 'src/components/01_atoms/GooglePlaceAutoCompleteInput/GooglePlaceAutoCompleteInput'
import Header from 'src/components/02_molecules/Header/Header'
import Hero from 'src/components/02_molecules/Hero/Hero'
import Page from 'src/components/03_organisms/Page/Page'
import QueryKey from 'src/structures/Enums/QueryKey.enum'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import IGooglePlaceResult from 'src/structures/Interfaces/IGooglePlaceResult'
import { IScansessionsResponse, ISetScanSessionLocation } from 'src/structures/Interfaces/IScansessionsResponse'
import { googlePlaceResultToAddress } from 'src/utils/address.utils'
import { isNotNullOrUndefined, isNotUndefined } from 'src/utils/guards.utils'

interface IMenuScannerLocationProperties {
    scanSession: IScansessionsResponse
}

const MenuScannerLocation: FC<IMenuScannerLocationProperties> = ({ scanSession }) => {
    const { t } = useTranslation()
    const queryClient = useQueryClient()

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [googlePlaceResult, setGooglePlaceResult] = useState<IGooglePlaceResult>(null)

    const autoCompleteOptions = useMemo(
        () => ({
            componentRestrictions: {
                country: ['BE', 'NL', 'LU'],
            },
        }),
        []
    )

    const setScanSessionLocationMutation = useMutation({
        mutationFn: (data: ISetScanSessionLocation) => SetScanSessionLocation(scanSession.id, data),
    })

    const handleStartScanning = useCallback(() => {
        setIsSubmitting(true)

        const locationPatch: ISetScanSessionLocation = {
            name: googlePlaceResult?.name ?? null,
            address: googlePlaceResultToAddress(googlePlaceResult),
        }

        setScanSessionLocationMutation.mutate(locationPatch, {
            onSuccess: () => {
                void queryClient.invalidateQueries({ queryKey: [QueryKey.SCAN_SESSION] })
            },
            onError: () => {
                toast.error(t('general.somethingWentWrong'))
                setIsSubmitting(false)
            },
        })
    }, [googlePlaceResult])

    const handlePlaceSelect = useCallback((place: google.maps.places.PlaceResult | null) => {
        if (isNotUndefined(place)) {
            setGooglePlaceResult(place)
        }
    }, [])

    return (
        <Page
            header={
                <Header
                    backButton={RoutePath.ROOT}
                    title={t('scansession.location.header.title')}
                />
            }
            footer={
                <div className='h-28'>
                    <div className='fixed flex items-center justify-center bottom-0 left-0 right-0 p-6'>
                        <Button
                            loading={isSubmitting}
                            disabled={!isNotNullOrUndefined(googlePlaceResult)}
                            className='w-full max-w-screen-lg overflow-hidden'
                            buttonStyle={ButtonStyle.PRIMARY}
                            onClick={handleStartScanning}>
                            <span className='overflow-hidden text-ellipsis inline'>
                                {t('scansession.location.button.confirmLocation')}
                            </span>
                        </Button>
                    </div>
                </div>
            }>
            <Hero>
                <h1 className='h3 text-center'>{t('scansession.location.title')}</h1>
            </Hero>

            <GooglePlaceAutoCompleteInput
                disabled={isSubmitting}
                options={autoCompleteOptions}
                placeHolder={t('scansession.location.input.placeholder', 'Examples: Bar Popular, Café Sport, ...')}
                onPlaceSelect={handlePlaceSelect}
            />
        </Page>
    )
}

export default MenuScannerLocation
