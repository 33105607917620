import { API_ROOT } from 'src/api/utilities/constants.api'

export const SCAN_SESSIONS_ENDPOINT = `${API_ROOT}/partners/scansessions`
export const SCAN_SESSION_BY_ID_ENDPOINT = (sessionId: string) => `${API_ROOT}/partners/scansessions/${sessionId}`
export const SCAN_SESSION_SET_LOCATION = (sessionId: string) =>
    `${API_ROOT}/partners/scansessions/${sessionId}/location`

export const SCAN_SESSION_UPLOAD_IMAGE = (sessionId: string) => `${API_ROOT}/partners/scansessions/${sessionId}/scans`

export const SCAN_SESSION_UPLOAD_IMAGE_REQUEST = `${API_ROOT}/partners/imageupload/generate`

export const SCAN_SESSION_DELETE_IMAGE = (sessionId: string, imageId: string) =>
    `${API_ROOT}/partners/scansessions/${sessionId}/scans/${imageId}`
