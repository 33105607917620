import { FC } from 'react'

import './imagefallback.scss'

type IImageFallbackProperties = Record<string, null>

const ImageFallback: FC<IImageFallbackProperties> = () => (
    <div className='image-fallback'>
        <p>Image failed to load</p>
    </div>
)

export default ImageFallback
